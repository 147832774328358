body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  color: #333;
}

.app {
  margin: 0 auto;
}


.title {
  font-size: 2.5em;
  margin: 0;
}

.description {
  font-size: 1.2em;
}

.tagline {
  font-style: italic;
  margin: 10px 0;
}

.info {
  color: #bbb;
}

