/* Features Section */
.features {
    background-color: #0f121b; 
    color: white;
    padding: 50px 20px;
    text-align: center;
  }
  
  .features h2 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .features-subtitle {
    font-size: 1.2rem;
    margin-bottom: 40px;
    color: #c9e3ff;
  }
  
  .feature-grid {
    padding-top: 25px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    justify-content: center;
  }
  
  /* Feature Card */
  .feature-card {
    background-color: #1a1e30;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    text-align: left;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.5);
  }
  
  .feature-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 15px;
  }
  
  .feature-card h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .feature-card p {
    font-size: 1rem;
    color: #a9d1ff;
  }
  
  .highlighted {
    font-size: 2rem;
    color: #ffa500;
  }
  