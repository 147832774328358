/* Footer Container */
.footer {
    background-color: #0f121b; /* Black background */
    color: white;
    padding: 20px 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  /* Brand Section */
  .footer-brand {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .footer-logo {
    width: 40px;
    height: 40px;
  }
  
  .footer-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
  }
  
  /* Icons Section */
  .footer-icons {
    display: flex;
    gap: 15px;
  }
  
  .footer-icon {
    width: 30px;
    height: 30px;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  
  .footer-icon:hover {
    transform: scale(1.1);
    opacity: 0.8;
  }
  
  /* Links Section */
  .footer-links {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .footer-link {
    font-size: 0.9rem;
    color: #c9e3ff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-link:hover {
    color: #ffffff;
  }
  