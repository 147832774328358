/* Hero Section */
.hero {
  background-color: #0f121b; /* Black background */
  color: white;
  padding:  20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.hero-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}

/* Left Side: Text */
.hero-text {
  max-width: 600px;
  text-align: left;
}

.hero h1 {
  font-size: 3.5rem;
  margin-bottom: 10px;
}

.hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #c9e3ff;
}

.hero-description {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #a9d1ff;
}

.hero-cta {
  text-decoration: none !important;
  padding: 10px 20px;
  background: #0056ff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;
}

.hero-cta:hover {
  background: #0041cc;
}

/* Right Side: Saturn Animation */
.hero-animation {
  position: relative;
  width: 300px;
  height: 300px;
}

.saturn-logo {
  width: 100%;
  animation: rotate 30s linear infinite;
}

/* Saturn Rotation Animation */
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
