/* Ecosystem Section */
.ecosystem {
    background-color: #0f121b; /* Black background */
    color: white;
    padding: 50px 20px;
    text-align: center;
  }
  
  .ecosystem h2 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .ecosystem-subtitle {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #c9e3ff;
  }
  
  /* Logos */
  .ecosystem-logos {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  
  .ecosystem-logo {
    width: 100px;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .ecosystem-logo:hover {
    transform: scale(1.1);
  }
  
  /* Call to Action */
  .ecosystem-cta p {
    font-size: 1.2rem;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .start-trading {
    text-decoration: none !important;
    padding: 10px 20px;
    background-color: #0056ff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .start-trading:hover {
    background-color: #0041cc;
  }
  