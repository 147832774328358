.header {
  position: fixed; /* Fix the header to the top of the viewport */
  top: 0;
  left: 0;
  width: 100%; /* Ensure it spans the full width of the screen */
  background-color: #0f121b; /* Keep the same background color */
  z-index: 1000; /* Ensure it stays above other elements */
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.header-container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 30px;
  margin-right: 10px;
}

.logo-text {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-buttons {
  display: flex;
  gap: 15px;
}

.docs-btn {
  text-decoration: none !important;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.docs-btn:hover {
  border-color: white;
  color: #00aaff;
}

.start-btn {
  text-decoration: none !important;
  background-color: #0056ff;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
}

.start-btn:hover {
  background-color: #0041cc;
}
